import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, we'll look at preparing for .... `}</p>
    <ul>
      <li parentName="ul">{`qualification screenings`}</li>
      <li parentName="ul">{`behavioral interviews`}</li>
      <li parentName="ul">{`coding-based assessments`}</li>
    </ul>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`By the end of this unit, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`describe the components of a typical multi-stage tech interview`}</li>
      <li parentName="ul">{`define the STAR method`}</li>
    </ul>
    <p>{`Typically interview loops involve an initial screening, a behaviorial interview, a techinical interview, and a coding interview.`}</p>
    <h2 {...{
      "id": "preparing-for-an-initial-screening",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparing-for-an-initial-screening",
        "aria-label": "preparing for an initial screening permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparing for an initial screening`}</h2>
    <p>{`Initial screenings ("phone screens") are used to ensure you meet the minimum qualifications for the position.  These screenings typically last anywhere from 10 minutes to 1 hour and are often conducted over the phone by a recruiter (as opposed to the hiring manager).  `}</p>
    <p>{`To prepare, ...`}</p>
    <ul>
      <li parentName="ul">{`Review the job description and listed qualifications, and be prepared to describe how you meet the qualifications  `}</li>
      <li parentName="ul">{`Double check the time zone and make sure you're in a quiet environment where you can talk freely`}</li>
      <li parentName="ul">{`Make sure all the devices you need are charged`}</li>
    </ul>
    <p>{`The initial screening is also an appropriate time to learn a bit more about the nature of the position and opportunities for growth within the organization.  `}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`when should I ask about compensation?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Interview loops are quite time-consuming, so if you have hard requirements, you might want to verify these at the initial stage of the interview loop.`}</p><p parentName="div">{`It is not unreasonable to ask about compensation range (a pay band) for the role, compensation structure (ex. salary vs RSUs), and an overview of benefits (ex. 401K matches, health insurance, etc.).  Receiving early answers to these questions can save both you and the organization time.  `}</p></div></div>
    <h2 {...{
      "id": "preparing-for-the-behavioral-interview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparing-for-the-behavioral-interview",
        "aria-label": "preparing for the behavioral interview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparing for the behavioral interview`}</h2>
    <p>{`Behavioral interviews are designed to gauge a) how well you fit the organization's culture and b) your ability to tell a focused story.  Often behavioral interview questions will relate to the organization's leadership principles or core values.`}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Examples`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><ul parentName="div">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.amazon.jobs/content/en/our-workplace/leadership-principles",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Amazon's leadership principles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.metacareers.com/culture/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Meta's core values`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://jobs.netflix.com/culture",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Netflix culture`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://openai.com/charter",
              "target": "_self",
              "rel": "nofollow"
            }}>{`OpenAI charter`}</a></li>
        </ul></div></div>
    <p>{`A common strategy for structuring your responses to behavioral questions to use the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Situation,_task,_action,_result",
        "target": "_self",
        "rel": "nofollow"
      }}><strong parentName="a">{`STAR method`}</strong></a>{` for storytelling.`}</p>
    <HTMLTable condensed mdxType="HTMLTable">
      <tbody>
        <tr>
            <td>
              <p>S</p>
            </td>
            <td rowSpan={2}>
              <p><strong>Situation/Task</strong></p>
            </td>
            <td rowSpan={2}>
              <p>
                <ul>
                  <li>Describe the situation/task you faced and the context of the story</li>
                  <li>When and where did this occur? <i>Why is it important?</i></li>
                </ul>
              </p>
            </td>
        </tr>
        <tr>
         <td>
          <p>T</p>
         </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td rowSpan={3}>
            <p>
              <ul>
                <li>What actions did you take?</li>
                <li>How did you accomplish X?</li>
              </ul>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>A</p>
          </td>
          <td>
            <p><strong>Action</strong></p>
          </td>
        </tr>
        <tr></tr>
        <tr>
            <td></td>
            <td></td>
            <td rowSpan={3}>
              <p>
                <ul>
                  <li>How did you measure success?</li>
                  <li>What results did you achieve?</li>
                </ul>
              </p>
            </td>
        </tr>
        <tr>
          <td>
            <p>R</p>
          </td>
          <td>
            <p><strong>Result</strong></p>
          </td>
        </tr>
    </tbody>
    </HTMLTable>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/yNdkpZ5MnbM?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/UQrTMxouDUY?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    {/*  - https://www.indeed.com/career-advice/interviewing/how-to-use-the-star-interview-response-technique
     - https://www.indeed.com/career-advice/interviewing/behavioral-interview-questions
     - https://www.carrus.io/blog/how-to-use-the-star-interview-method-to-nail-any-tech-interview-copy
     - https://www.themuse.com/advice/star-interview-method
     - https://www.betterup.com/blog/star-interview-method */}
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`What does the organization value?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If it isn't provided to you, ask your recruiter for information on the organization's learship principles or core values.  Usually behavioral questions will center around one or more of these LPs.  Since you likely won't know what the interviewer will ask you ahead of time, it's best to prepare a story or two for each LP.  It's also not uncommon for behavioral interviews to be split across multiple stages or interviewers who each focus on a single LP.`}</p></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Stories tied to the role`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Prepare multiple stories based on what you feel the qualities and skills are most important to the role.`}</p></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Write it down`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Prepare your stories by writing a "script" according the STAR method. Writing it down will help you organize your thoughts.  `}</p></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Ask a friend to listen`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Try to tell your stories to a friend or family member.  Listen to their feedback and adjust as appropriate.`}</p></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Bring your notes`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`It's fine to go into your interview with some notes (ex. STAR-based bullets).  This shows you've prepared!`}</p></div></div>
    <h2 {...{
      "id": "sample-behavioral-interview-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sample-behavioral-interview-questions",
        "aria-label": "sample behavioral interview questions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample behavioral interview questions`}</h2>
    <ul>
      <li parentName="ul">{`Have you ever had to develop a new skill on the job? Tell me about your approach to the learning process. `}</li>
      <li parentName="ul">{`Describe a time when you had a tight deadline to meet. How did you get things done? `}</li>
      <li parentName="ul">{`Have you ever had a direct disagreement with your manager? How did you handle that situation? `}</li>
      <li parentName="ul">{`Tell me about one of your proudest professional accomplishments. `}</li>
      <li parentName="ul">{`Describe a time you motivated your team to achieve results. What was your approach?`}</li>
      <li parentName="ul">{`Give me an example of a time when you failed to hit your goals. How did you respond and what did you do?`}</li>
      <li parentName="ul">{`Have you ever had to push back on a key stakeholder? What did that interaction look like?`}</li>
      <li parentName="ul">{`Describe the projects you typically enjoy most.`}</li>
      <li parentName="ul">{`Share an example of a time when you had to shift priorities quickly. How did you handle that situation?`}</li>
      <li parentName="ul">{`Have you ever managed an employee who was underperforming? How did you handle the situation?`}</li>
      <li parentName="ul">{`Share an example of a time when you went above and beyond what is expected of your role.`}</li>
      <li parentName="ul">{`Share an example of a project you needed buy-in from various stakeholders to complete. `}</li>
      <li parentName="ul">{`Explain a situation where you overcame a challenge at work.`}</li>
      <li parentName="ul">{`Tell me about a time when you had to navigate changes at work.`}</li>
      <li parentName="ul">{`Describe a time when you had to motivate your peers. How did you do it and what was the outcome?`}</li>
      <li parentName="ul">{`Tell me about the last project you owned and were really proud of.`}</li>
      <li parentName="ul">{`Share a time when things did not go your way. How did you respond and what did you learn?`}</li>
      <li parentName="ul">{`Share an example of a time when you were under immense pressure at work. How did you handle the situation?`}</li>
      <li parentName="ul">{`Tell me about a time when you surprised yourself.`}</li>
      <li parentName="ul">{`Have you ever spent too much time on a project? How did you recover?`}</li>
      <li parentName="ul">{`Describe a time when you helped a coworker achieve their goals.`}</li>
      <li parentName="ul">{`Give me an example of a time when you performed well under pressure.`}</li>
      <li parentName="ul">{`Tell me about a time when you had to make a tough decision.`}</li>
      <li parentName="ul">{`Tell me about a time when you made the wrong decision.`}</li>
    </ul>
    <h2 {...{
      "id": "coding-interview-prep",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#coding-interview-prep",
        "aria-label": "coding interview prep permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coding interview prep`}</h2>
    <p>{`If you're interviewing for an individual contributor role, you're likely to face a coding interview.  Sometimes these are project-based (ex "you have two days to build a binary classifier for the provided dataset"), but often they are live coding challenges (coding interviews).`}</p>
    <h3 {...{
      "id": "project-based-coding-skills-assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#project-based-coding-skills-assessment",
        "aria-label": "project based coding skills assessment permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Project-based coding skills assessment`}</h3>
    <p>{`In project-based coding skill assessments usually present a minature challenge that must be completed within a period of days.  After submitting the project, you'll then have a presentation where you describe your solution and answer questions about limitations and possible improvements.  `}</p>
    <h3 {...{
      "id": "coding-interviews",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coding-interviews",
        "aria-label": "coding interviews permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coding interviews`}</h3>
    <p>{`With coding interviews, you are typically presented with a challenge that draws on knowledges of data structures and algorithms. Often you will share your screen with your own code editor or a collaborative editor where the interviewer can observe you type.  When approaching these coding challenges, it is important to ask clarifying questions about the requirements and assumptions.  The objective of the assessment is partially to verify you are a competent coder, but more crucially that you know how to think through a problem.  You should talk through your approach and line of thinking as you progress through the interview.`}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Choose your language`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Typically you'll be free to pick a programming language from the set associated with the job ad.`}</p></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Time to think`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`It's fine to ask for a bit of time to think about the problem before you start.`}</p></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Outline the solution`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`You may want to stub out parts of your solution as you go:`}</p><blockquote parentName="div">
          <p parentName="blockquote"><em parentName="p">{`I want to break this problem down a bit further.  I'm going to need to write a couple of functions: the first will compute X, and the second will compute Y given X.  Once I have that, I'll ...`}</em></p>
        </blockquote></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`This is a test`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`You may want to write test cases if none are provided to ensure you understand what constitutes success ("given this input, we expect this output")`}</p></div></div>
    <p>{`Once you provide an initial solution, the interviewer will often change some aspect of the problem and ask you to revise your solution or think through what such a revision would require (ex. "Imagine instead you were starting with X.  How would you do this differently?").`}</p>
    <p>{`Because the problems often center on classic computer science algorithms and data structures that you may not use (or at least implement) everyday, even excellent programmers will benefit from review and preparation. Typically you'll want to devote some time each day for a period of weeks to review and run through programming drills.  There a number of platforms and resources to help you prepare for these kinds of interviews.  `}<a parentName="p" {...{
        "href": "https://leetcode.com/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`LeetCode`}</a>{` is perhaps the most commonly used such resource and provides both free and paid plans.  `}<a parentName="p" {...{
        "href": "https://leetcode.com/problemset/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Problems`}</a>{` are ranked in difficulty (easy, medium, hard) and can be organized into study plans.`}</p>
    <h3 {...{
      "id": "examples-of-coding-challenges",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#examples-of-coding-challenges",
        "aria-label": "examples of coding challenges permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Examples of coding challenges`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[easy]`}</code>{`: reverse a string`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[easy]`}</code>{`: detect if a string is a palindrome`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[easy]`}</code>{`: find the longest common prefix in a pair of strings`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[easy]`}</code>{`: determine if `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`string 1`}</code>{` is an anagram of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`string 2`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[easy/medium]`}</code>{`: create a trie (prefix tree)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[medium]`}</code>{`: compute the edit distance of any two strings`}
        <ul parentName="li">
          <li parentName="ul">{`checks knowledge of dynamic programming`}</li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[medium]`}</code>{`: generate all permutations of size `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span></span></span></span></span>{` for `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`list 1`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[medium]`}</code>{`: detect cycles in a graph`}
        <ul parentName="li">
          <li parentName="ul">{`checks knowledge of graph search algorithms (ex. breadth-first search)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`By the end of your preparation, you should feel comfortable tackling most `}<strong parentName="p">{`medium`}</strong>{` difficulty problems.`}</p>
    <h2 {...{
      "id": "the-informational-interview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-informational-interview",
        "aria-label": "the informational interview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The informational interview`}</h2>
    <p>{`Throughout the interview process you should take advantage of any opportunity that arises to ask questions about the role, organization, and team.  This will help you prepare for subsequent stages, and make an informed decision if you're offered the position.`}</p>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`general prep`}</strong>{`: The University of Arizona's `}<a parentName="li" {...{
          "href": "https://career.arizona.edu/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Career Center`}</a>{` provides `}<a parentName="li" {...{
          "href": "https://career.arizona.edu/videos/?shared_category=interviewing",
          "target": "_self",
          "rel": "nofollow"
        }}>{`a series of videos on general interview preparation`}</a>
        <ul parentName="li">
          <li parentName="ul">{`general advice on `}<a parentName="li" {...{
              "href": "https://career.arizona.edu/channels/data-technology/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`pursuing tech careers`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`mock interviews`}</strong>{`: TheUniversity of Arizona's Graduate Career Services office `}<a parentName="li" {...{
          "href": "https://gradcenter.arizona.edu/career-services",
          "target": "_self",
          "rel": "nofollow"
        }}>{`provides virtual mock interviews (by appointment)`}</a></li>
      <li parentName="ul"><strong parentName="li">{`coding interview`}</strong>{`: YouTube playlist for the University of Arizona Data Lab's `}<a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLohiBOvMMwCu6hXfXG-GX3u_T2Zns0qCf",
          "target": "_self",
          "rel": "nofollow"
        }}>{`workshop on cracking the coding interview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      